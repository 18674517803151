import { Component, OnInit } from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),

      transition(':enter', [
        style({opacity: 0}),
        animate('4s')
      ]),
    ])
  ]
})
export class TitleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
