import {Component, OnInit} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css'],
  animations: [
    trigger('fadeg', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate('1.5s 3s'),
      ])
    ]),
    trigger('fadel', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate('1.5s 4s'),
      ])
    ]),
    trigger('fader', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate('1.5s 5s'),
      ])
    ])
  ]
})
export class LinksComponent implements OnInit {
  resumePdf = '/Users/akshithgara/armbm/src/assets/Resume.pdf';

  constructor() {
  }

  ngOnInit() {
  }


}
